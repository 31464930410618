import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageViewerPopupComponent } from '../components/image-viewer-popup/image-viewer-popup.component';

@Directive({
  selector: '[ImageViewer]',
  standalone: true
})
export class ImageViewerDirective {

  @Input() ImageViewer!: string;

  constructor(private dialog: MatDialog) {}
  
  @HostListener('click')
  onClick() {
    if (this.ImageViewer) {
      const dialogRef = this.dialog.open(ImageViewerPopupComponent, {
        data: { src: this.ImageViewer, dialogRef: null },
        panelClass: 'image-dialog-container',
        width: `auto`,   // Set width based on image width
        height: `auto`, // Set height based on image height
        minWidth: '250px',
      });

    dialogRef.componentInstance.data.dialogRef = dialogRef;
    }
  }
  
}
