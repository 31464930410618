<audio id="audio" #audio></audio>
<!-- Button -->
<div  *transloco="let lan;scope:'header'">
<div
    class="cursor-pointer p-2"
    matRipple
    [matMenuTriggerFor]="userActions"
    #menuTrigger="matMenuTrigger">
    <div class="flex relative items-center">
        <div class="w-9 h-9 bg-slate-300 flex items-center justify-center text-xl rounded-full border-gray-700 shrink-0">
            <ng-container *ngIf="userImage">
                <img class="object-cover w-full h-full rounded-full" [src]="fileUrl+userImage" alt="Contact avatar" loading="lazy"/>
            </ng-container>
            <ng-container *ngIf="!userImage">
                {{userName |shortName}}
            </ng-container>
        </div>
        <span [ngClass]="{'bg-[#0CB482]':status=='Online','bg-slate-400':status=='Offline','bg-orange-600':status=='Busy'}" class="absolute left-[28px] top-[22px] w-2.5 h-2.5 rounded-full lg:left-11 lg:top-4">
        </span>
        <div class="hidden flex-col mr-2 lg:flex">
            <p class="text-lg mt-1.5 ml-6 font-semibold user-name">
                {{userName}}
            </p>
            <span *ngIf="sip_name" class="text-[16px] font-normal ml-6 mt-[-5px]">Extension No. {{sip_name}}</span>
        </div>
        @if(!menuTrigger.menuOpen){
            <img src="../../../../assets/icons/arrow-down-sm.svg" class="w-3 ml-2 animate__animated animate__fadeIn"/>
        }@else {
            <img src="../../../../assets/icons/up-arrow.svg" class="w-3 ml-2 animate__animated animate__fadeIn"/>
        }
    </div>
</div>

<mat-menu
    [xPosition]="'before'"
    class="border-black user-menu mt-[4px] mr-[-3px] rounded-4"
    #userActions="matMenu" >
    <div class="flex flex-col flex-auto rounded-lg" (click)="$event.stopPropagation();">
        <div class="p-4  pb-0 flex flex-col">
            <span class="text-[16px] font-normal items-center flex mt-1.5">My Status 
                <mat-icon *ngIf="isStatusChanging" svgIcon="feather:loader" class="ml-1 animate-spin icon-size-4"></mat-icon></span>
            <div class="px-3">
                <mat-slider class="w-full mt-[4px]"
                    [max]="3"
                    [min]="1"
                    [step]="1"
                    [disabled]="isStatusChanging"
                    (change)="onSliderChange($event)" 
                >
                <input matSliderThumb [(value)]="statusInt"  #slider [ngClass]="{'hidden':isStatusChanging}">
                </mat-slider>
            </div>    
            <div class="flex flex-row justify-between mt-[-15px]" [ngClass]="{'pointer-events-none':isStatusChanging}">
                <div class="text-[14px] tracking-[-0.32px] leading-[20px] cursor-pointer" [ngClass]="status=='Online' ? 'font-semibold' : 'font-normal'" (click)="onStatusChange('Online')">Online</div>
                <div class="text-[14px] tracking-[-0.32px] leading-[20px] cursor-pointer" [ngClass]="status=='Busy' ? 'font-semibold' : 'font-normal'" (click)="onStatusChange('Busy')">Direct Calls</div>
                <div class="text-[14px] tracking-[-0.32px] leading-[20px] cursor-pointer" [ngClass]="status=='Offline' ? 'font-semibold' : 'font-normal'" (click)="onStatusChange('Offline')">Offline</div>
            </div>
        </div>
        <mat-divider class="m-0 mt-[5px]"></mat-divider>
        <div class="p-3 py-0">
            <button mat-menu-item class="p-0" (click)="myProfile()">
                <img class="w-[20px] h-[20px] mr-1" src="../../../../assets/icons/user.svg" />
                <span class="text-[16px] font-normal">{{lan('header.my-profile')}}</span>
            </button>
            <button mat-menu-item class="p-0 mt-[-12px]"
                (click)="signOut()">
                <img class="w-[20px] h-[20px] mr-1" src="../../../../assets/icons/arrow-right.svg" />
                <span class="text-[16px] font-normal">{{lan('header.sign-out')}}</span>
            </button>
        </div>
        <!-- for tablet view -->
        <mat-divider class="m-0 lg:hidden"></mat-divider>
        <div class="flex flex-col p-3 pt-2 py-0 lg:hidden">
            <p class="text-lg font-semibold user-name max-w-[215px] truncate">
                {{user.firstname +' ' }} {{user.lastname || ''}}
            </p>
            <span *ngIf="sip_name" class="text-[16px] font-normal">Extension No. {{sip_name}}</span>
        </div>
    </div>
</mat-menu>

</div>

