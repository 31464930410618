import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { ToastrService } from 'ngx-toastr';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { environment } from 'environments/environment';

@Component({
  selector: 'call-list-info',
  standalone: true,
  imports: [CommonModule, PhoneNumberPipe, ShortNamePipe],
  templateUrl: './call-list-info.component.html',
  styleUrl: './call-list-info.component.scss'
})
export class CallListInfoComponent {
  
  @Input() type:string;

  @Input() callerSession;

  //@Input() call_Details: any;

  @Output() pickCall = new EventEmitter<any>

  @Output() rejectCall = new EventEmitter<any>

  @Output() endCall = new EventEmitter<any>

  @Output() holdCall = new EventEmitter<any>

  public fileURL = environment.imgURL

  constructor(private callSharedService:CallCenterSharedService,
    private toastr : ToastrService
  )
  {

  }

  onPickCall()
  {
    if (this.callSharedService.activeCallerSession) {

      if (this.callSharedService.activeCallerSession.callDetails.call_id != this.callerSession.callDetails.call_id) {

        this.toastr.warning("Please hold current call")
        return
      }

    }
    //this.callerSession.session.state = 'connect'
    this.pickCall.emit(this.callerSession)
  }

  onRejectCall()
  {
    
    this.rejectCall.emit(this.callerSession)
  }

  onEndCall()
  {
    this.endCall.emit(this.callerSession)
  }
  
  onHoldCall()
  {
    if(this.callerSession.customValues.isCallOnHold == true)
      {
        
        if(this.callSharedService.activeCallerSession)
          {
            // if(this.callSharedService.activeCallerSession.customValues.call_id != this.session.customValues.call_id)
            //   {
                if(this.callSharedService.activeCallerSession?.customValues.isCallOnHold == false &&
                  this.callSharedService.activeCallerSession?.state != 'Terminated')
                  {
               
                    this.toastr.warning("Please Hold Current Ongoing Call !")
                     return
                  }
              //}
          }
      }

    this.type = !this.callerSession.customValues.isCallOnHold ? 'hold' : 'ongoing'
    this.holdCall.emit(this.callerSession)
  }

  formatTime(seconds: number): string {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return mins+'m:'+secs+'s'
  }

  // private pad(value: number): string {
  //   return value < 10 ? '0' + value : value.toString();
  // }
  isNullorEmpty(value: string | null): boolean {
    return value === null || value === "" || value === undefined;
  }
}
