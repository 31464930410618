import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppEventType } from 'app/core/enums/common.enum';
import { CommonService } from 'app/core/services/common.service';
import { DidsService } from '../../services/dids.service';
import { DataFilterRequest } from 'app/core/models/grid-filter.models';
import { SubSink } from 'subsink';
import { DIDNumber } from '../../models/dids.models';
import { AppConstants } from 'app/core/constants/app.constants';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { UserService } from 'app/modules/organization/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'outgoing-number-select',
  standalone: true,
  imports: [CommonModule,  FormsModule, NgSelectModule, PhoneNumberPipe, NgxSkeletonLoaderModule],
  templateUrl: './outgoing-number-select.component.html',
  styleUrl: './outgoing-number-select.component.scss'
})
export class OutgoingNumberSelectComponent {
  selectedItem: any;
  private searchTerm$ = new Subject<string>();
  subs: SubSink = new SubSink();
  phone_numbers: DIDNumber[];
  isDropdownOpen: boolean;
  is_loaded: boolean = true;
  private sendAsNumberSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private didservice: DidsService,
    private callcenterShared: CallCenterSharedService,
    private userService: UserService,
    private toaster: ToastrService
  ){}

  ngOnInit(){
    console.log(typeof(localStorage.getItem(AppConstants.SEND_AS_NUMBER)));
    console.log((localStorage.getItem(AppConstants.SEND_AS_NUMBER)).length,'length');

    this.sendAsNumberSubscription = this.didservice.currentSendAsNumber$?.subscribe(
      (sendAsNumber) => {
        this.selectedItem = { "did": this.removeCountryCode(sendAsNumber) };
      }
    );
    
    if(localStorage.getItem(AppConstants.SEND_AS_NUMBER)){
      this.didservice.current_send_as_number = this.removeCountryCode(localStorage.getItem(AppConstants.SEND_AS_NUMBER))
    }else{
      this.callcenterShared.fetchAgentDetails();
    }
    // this.getPhoneNumbers();
    this.searchTerm$
      .pipe(
        debounceTime(300), // Wait for 300ms pause in events
        distinctUntilChanged(), // Only emit when the current value is different than the last
      )
      .subscribe(term => {
        this.getPhoneNumbers(term)
      });
  }

  ngAfterViewInit(){
    this.subs.sink = this.commonService.on(AppEventType.SuborgSwitch).subscribe(event => {
      this.getPhoneNumbers();
    });
  }

  onAfterOpen(){
    this.isDropdownOpen = true
    setTimeout(() => {
      let searchInput = document.getElementById('search-number-field') as HTMLInputElement;
      searchInput?.focus();
    }, 300);
  }

  getPhoneNumbers(term?: any) {
    this.is_loaded= false;
    let option: DataFilterRequest = {
        sort: [{ colname: 'name', direction: 'asc' }],
        filters: []
    };
    if (term?.toLowerCase()) {
      option.filters.push(
        {
          "conditions":[
            { colname: 'did', condition: 'contains', value: term.toLowerCase(), operator: 'AND' }
          ], 
          "operator":"AND"
        });
    }
    this.subs.add(this.didservice.getAll(option, 0, 50).subscribe((resp)=>{
      this.phone_numbers =  resp.data?.result;
      this.is_loaded= true;
      this.cdr.detectChanges();
    }));
  }

  removeCountryCode(phoneNumber: string): string {
    if (phoneNumber?.startsWith('+1')) {
        return phoneNumber.slice(2);
    }
    return phoneNumber;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.sid === o2.sid : o1 === o2;
  }

  selectItem(item: DIDNumber) {
    if(!(item instanceof Event)){
      const previousItem = this.selectedItem;
      this.selectedItem = item;
      this.subs.sink = this.userService.setSendAsNumber(item.did).subscribe({
        next: (res) => {
          if(res&&res.http_status==200){
            localStorage.setItem(AppConstants.SEND_AS_NUMBER, item.did);
            this.didservice.current_send_as_number = item.did;
          }else{
            this.selectedItem = previousItem;
            this.toaster.error("Failed to update send as number");
          }
          
        },
        error: (err) => {
          this.selectedItem = previousItem;
          this.toaster.error("Internal server error");
          console.log(err);
        }
        })
    }
  }

  onSearchTermChange(event: Event) {
    const inputElement = event.target as HTMLInputElement; // Cast to HTMLInputElement
    let searchTerm = inputElement.value;
    searchTerm = searchTerm.replace(/\D/g, '');
    this.searchTerm$.next(searchTerm);
  }

  closeDropdown(ngSelect: any) {
    ngSelect.close(); 
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.searchTerm$.unsubscribe(); // Clean up subscriptions on destroy
  }

}
