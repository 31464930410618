
export enum UserTypes {
    SuperUser = "545514976ad64ba23911d485536249c9",
    Admin = "73acd9a5972130b75066c82595a1fae3",
    TenantUser = "9e2ef94jad123adb6369225542k45w50",
    User = "8e2ef94cad245adb8089356242f49e55",
    Customer = "ceg656sdfadsf56565656sdfsadfmb66"
}

export enum SystemRoles {
    SuperAdmin = 'SUPERADMIN',
    Staff = 'STAFF',
    Admin = 'ADMIN',
    Customer = 'CUSTOMER'
}