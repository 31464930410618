import { UserWrapper, UserWrapperFlat } from './../models/user-wrapper';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/core/services/base.service';
import { DataFilterResponse } from 'app/core/models/grid-filter.models';
import { User, UserSettings } from '../models/user';
import { Subject } from 'rxjs';
import { SfHttpService } from 'app/shared/services/sf-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  public storeData$ = new Subject<any>();

    private storeName: string = 'users'

  constructor(private http: HttpClient, private sfHttpService: SfHttpService) { super(); }

  filter(option: any, offset: number = 0, limit:number=50) { 
    let url = `${this.baseURL}/users/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<User[]>>(url, option);
  }

  filterFromLocalDB(option: any, offset: number = 0, limit:number=10) { 
    const apiCallurl = `${this.baseURL}/users/filter/?limit=${limit}&offset=${offset}`;
      return this.sfHttpService.post<User>(apiCallurl,option,this.storeName,limit,offset,this.storeData$).subscribe(res=>{});
  }

  getFiltered(request: any, offset: number = 0, limit: number = this.dataLimit) { //latest filterAPI
    let url = `${this.baseURL}/user/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<any>(url, request);
}

  getAll() {
    return this.http.get<DataFilterResponse<UserWrapper[]>>(`${this.baseURL}/users/`);
  }

  getById(id: number) {
    return this.http.get<DataFilterResponse<User>>(`${this.baseURL}/users/${id}/`);
  }

  create(user: User) {
    return this.http.post<DataFilterResponse<User>>(`${this.baseURL}/users/`, user);
  }

  update(id: number, user: User) {
    return this.http.put<DataFilterResponse<User>>(`${this.baseURL}/users/${id}/`, user);
  }

  patch(id: number, req: any) {
    return this.http.patch<User>(`${this.baseURL}/users/${id}/`, req);
  }

  delete(id: number) {
    return this.http.delete(`${this.baseURL}/users/${id}/`);
  }

  getUserSettings(userId: number) {
    return this.http.get<{data:UserSettings}>(`${this.baseURL}/users/${userId}/settings/`);
  }

  updateUserSettings(userId: number, request: any) {
    return this.http.patch<UserSettings>(`${this.baseURL}/users/${userId}/settings/`, request);
  }

  checkSIPExist(request: any){
    return this.http.post<any>(`${this.baseURL}/sip-exists/`, request);
  }

  deleteSfAccount() {
    return this.http.post<DataFilterResponse<any>>(`${this.baseURL}/tenant/delete/email/`, null);
  }

 
 //Set sendas number
  setSendAsNumber(number: string){
    return this.http.post<DataFilterResponse<{status:boolean,send_as_number: string}>>(this.baseURL+'/user/sip/send_as',{send_as_number: number});
  }

}
