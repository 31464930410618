import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { OutboundCallComponent } from '../outbound-call/outbound-call.component';
import { SipInboundFunctionService } from '../../services/sip-inbound-function.service';
import { CallListComponent } from '../call-list/call-list.component';
import { SipOutboundFunctionService } from '../../services/sip-outbound-function.service';
import { SubSink } from 'subsink';
import { CustomerContact } from 'app/modules/tenant-customers/models/companies.models';
import { CallListInfoComponent } from '../call-list-info/call-list-info.component';
import { ActiveCallInfoComponent } from '../active-call-info/active-call-info.component';

@Component({
  selector: 'call-center',
  standalone: true,
  imports: [CommonModule,OutboundCallComponent,CallListInfoComponent,
    ActiveCallInfoComponent,
  CallListComponent],
  templateUrl: './call-center.component.html',
  styleUrl: './call-center.component.scss',
 
})
export class CallCenterComponent implements OnInit, OnDestroy {

  @Output() scrollToTopEvent = new EventEmitter<void>();

  @ViewChild('active_call_info') active_call_info: ActiveCallInfoComponent;

  private isAllSuborg:boolean = false;

  private subs: SubSink = new SubSink();

 public contactList: CustomerContact[];

 public show = false

  constructor(public callcenterShared: CallCenterSharedService,
    private sipInboundFunctions: SipInboundFunctionService,
    private sipOutboundFunctions: SipOutboundFunctionService)
  {

  }
  ngOnInit(): void {}

  onPickCall(session:any)
  {
    this.sipInboundFunctions.oncallConnect(session)
  }
  onRejectCall(callerSession:any)
  {
    if(callerSession.customValues.callType == 'inbound')
      {
        this.sipInboundFunctions.rejectCall(callerSession)
      }
    if(callerSession.customValues.callType == 'outbound')
      {
          this.sipOutboundFunctions.rejectCall(callerSession)
      } 

  }
  onMuteCall(callerSession:any)
  {
    callerSession.customValues.isMuted = !callerSession.customValues.isMuted
    this.callcenterShared.muteCall(callerSession)
  }
  onEndCall(callerSession:any)
  {
    if(callerSession.customValues.callType == 'inbound')
    {
      this.sipInboundFunctions.endCall(callerSession)
    }
    else if(callerSession.customValues.callType == 'outbound')
      {
        this.sipOutboundFunctions.endCall(callerSession)
      }
   
  }
  onHoldCall(callerSession:any)
  {
    if(callerSession.customValues.callType == 'inbound')
      {
        this.sipInboundFunctions.holdCall(callerSession)
      }
    else if(callerSession.customValues.callType == 'outbound')
      {
        this.sipOutboundFunctions.holdCall(callerSession,true)
      }
  }

  scrollToTopFromChild() {
    this.scrollToTopEvent.emit();
  }

  ngOnDestroy(): void {
      
    this.subs.unsubscribe()
    this.sipInboundFunctions.destroy();
    this.sipOutboundFunctions.destroy();
  }
}
