import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactSearchComponent } from 'app/modules/tenant-customers/components/contact-search/contact-search.component';
import { OutboundDialerComponent } from '../outbound-dialer/outbound-dialer.component';
import { TranslocoModule } from '@ngneat/transloco';
import { RecentCallHistoryComponent } from '../recent-call-history/recent-call-history.component';
import { DataFilterRequest, GFilterParam } from 'app/core/models/grid-filter.models';
import { SubSink } from 'subsink';
import { CustomerContact } from 'app/modules/tenant-customers/models/companies.models';
import { CallLog } from '../../models/reports.models';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { CallCenterSharedService } from '../../services/call-center-shared.service';

@Component({
  selector: 'outbound-call',
  standalone: true,
  imports: [CommonModule,ContactSearchComponent,
             OutboundDialerComponent,TranslocoModule,FormsModule,
             ReactiveFormsModule,RecentCallHistoryComponent, MatIconModule],
  templateUrl: './outbound-call.component.html',
  styleUrl: './outbound-call.component.scss'
})
export class OutboundCallComponent implements OnInit, OnDestroy {

  @ViewChild(OutboundDialerComponent) dialer:OutboundDialerComponent;

  @ViewChild(ContactSearchComponent) contactSearch : ContactSearchComponent

  @Output() triggerScrollToTop = new EventEmitter<void>();

  public tabShowType : string = "History";

  private subs: SubSink = new SubSink();

  searchControl = new FormControl();

  searchText : any

  constructor(private phonenumberpipe:PhoneNumberPipe, public callcenterShared: CallCenterSharedService)
  {

  }


  ngOnInit(): void {}

addForCall(number){
  if(number.length<6){
    this.dialer.dialNumber=number;
  }else{
    this.dialer.dialNumber=this.phonenumberpipe.transform(number);
  }
  
  if (this.dialer.dialInput) {
    this.triggerScrollToTop.emit();
    setTimeout(() => {
      this.dialer.dialInput.nativeElement.focus();
    }, 400);
  }
}

searchInContacts(key){
  this.contactSearch.selectedLetter = ''
  this.tabShowType = "Contact"
  let request:DataFilterRequest;
  

  const val = key.replace(/\D/g, '')
  console.log('keyval',val)
  if(val!=""){

       request = {

        filters: [{conditions:[
          {
                "colname": "mobile",
                "condition": "startswith",
                "value": val,
                "operator": "OR"
  
          },
          {
                "colname": "phone",
                "condition": "startswith",
                "value": val,
                "operator": "OR"
          }

          ],"operator": "AND"}],
        sort: [],
        base_filters: []
      };

    }
    this.contactSearch.page.offset=0;
    this.contactSearch.fetchFilteredDataFromAPI(null,request?.filters, 0, false);


}


clearSearch(){
  this.searchControl.setValue('');
}

ngOnDestroy(): void {
    this.subs.unsubscribe()
}

}
