import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-viewer-popup',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './image-viewer-popup.component.html',
  styleUrl: './image-viewer-popup.component.scss'
})
export class ImageViewerPopupComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { src: string, dialogRef: MatDialogRef<ImageViewerPopupComponent> }) {}

  close() {
    this.data?.dialogRef.close();
  }

}
