import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { CallCenterComponent } from '../call-center/call-center.component';
import { CallListComponent } from '../call-list/call-list.component';
import { SipInboundFunctionService } from '../../services/sip-inbound-function.service';
import { SipOutboundFunctionService } from '../../services/sip-outbound-function.service';

@Component({
  selector: 'call-center-panel',
  standalone: true,
  imports: [CommonModule,CallCenterComponent,
    CallListComponent,
  ],
  templateUrl: './call-center-panel.component.html',
  styleUrl: './call-center-panel.component.scss',

})
export class CallCenterPanelComponent implements OnInit {

  @ViewChild('scrollableContainer') scrollableContainer: ElementRef;

  public opened: boolean;


  constructor(public callcenterShared: CallCenterSharedService,
    public sipInboundFunctions: SipInboundFunctionService,
    public sipOutboundFunctions:SipOutboundFunctionService ) {
  }

  ngOnInit(): void {

    this.callcenterShared.popupOpenStatus.subscribe((val)=>{
      
    if(val == true)
    {
      this.opened = true
    }
    else if(val == false)
    {
      this.opened = false
    }   
  })


  }


  onPickCall(session:any)
  {
    this.sipInboundFunctions.oncallConnect(session)
  }
  onRejectCall(session:any)
  {
    this.sipInboundFunctions.rejectCall(session)
  }
  onEndCall(session:any)
  {
    if(session.customValues.callType == 'inbound')
      {
        this.sipInboundFunctions.endCall(session)
      }
    else if(session.customValues.callType == 'outbound')
      {
          this.sipOutboundFunctions.endCall(session)
      }
  }
  onHoldCall(session:any)
  {
    if(session.customValues.callType == 'inbound')
      {
        this.sipInboundFunctions.holdCall(session)
      }
    else if(session.customValues.callType == 'outbound')
      {
        this.sipOutboundFunctions.holdCall(session)
      }
  }

  closePanel()
  {
      this.callcenterShared.showDialer = false
      this.callcenterShared.popupOpenStatus.next(false)
  }

  scrollToTop() {
    this.scrollableContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  
}
