import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropComponent } from '../components/image-crop/image-crop.component';

@Directive({
  selector: '[ImageCropper]',
  standalone: true
})
export class ImageCropperDirective {

  @Output() onAfterCropped = new EventEmitter<File>();

  private fileInput: HTMLInputElement;

  constructor(private el: ElementRef, private dialog: MatDialog, private cdr: ChangeDetectorRef) {
    this.fileInput = el.nativeElement;
  }

  @HostListener('change', ['$event'])
  onChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const dialogRef = this.dialog.open(ImageCropComponent, {
          width: '1000px',
          data: { image: reader.result, filename: file.name },
          panelClass: 'custom-dialog-container'
        });
        
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            // Emit the cropped image file
            this.onAfterCropped.emit(result);
          }
          // Reset the file input after closing the dialog
          this.resetFileInput();
        });
      };
      reader.readAsDataURL(file);
    }
  }

   private resetFileInput() {
    this.fileInput.value = ''; // Clear the file input value
  }

}
