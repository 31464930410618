import { Injectable } from '@angular/core';
import { AppSharedConstants } from 'app/core/constants/app-shared.constants';
import { GFilterParam, TbFilterMetaModel } from 'app/core/models/grid-filter.models';
import { NgxIndexedDBService, ObjectStoreMeta } from 'ngx-indexed-db';
import { from, map, Observable, switchMap } from 'rxjs';
import { decodeDataWithToken, encodeDataWithToken } from '../functions/encription.utils';
import { AppConstants } from 'app/core/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class IndexDbHttpService {

  private indexDBName = AppSharedConstants.INDEXDB_NAME;

  private dbConnection: IDBDatabase | null = null;

  constructor(
     private dbService: NgxIndexedDBService
  ) {
  }
  createObjectStore(storeName: string, key: string = 'id', isAutoIncrement: boolean = true): Promise<void> {

    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      const newVersion = currentVersion + 1;
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, newVersion);

        request.onupgradeneeded = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, { keyPath: key, autoIncrement: isAutoIncrement });
          }
        };

        request.onsuccess = () => {
          resolve();
        };

        request.onerror = (event) => {
          reject('Failed to create object store');
        };
      });
    });
  }

  addUpdateMetaStore(storeName: string, item: any): Promise<any> {

    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise<any>((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, currentVersion);

        request.onsuccess = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          const transaction = db.transaction([storeName], 'readwrite');
          const store = transaction.objectStore(storeName);
          const encryptedData = encodeDataWithToken(item, localStorage.getItem(AppConstants.AUTH_TOKEN))
          const encryptedItem = { storeName: item.storeName, encryptedData };

          const putRequest = store.put(encryptedItem);

          putRequest.onsuccess = () => {
            resolve('Record updated successfully');
          };

          putRequest.onerror = () => {
            reject('Failed to update record');
          };
        };
      });
    });
  }

  updateRecord(storeName: string, item: any): Promise<any> {
    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise<any>((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, currentVersion);

        request.onsuccess = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          const transaction = db.transaction([storeName], 'readwrite');
          const store = transaction.objectStore(storeName);
          const encryptedData = encodeDataWithToken(item, localStorage.getItem(AppConstants.AUTH_TOKEN))
          let putRequest = null;
          if (item.id) {
            const encryptedItem = { id: item.id, encryptedData };
            putRequest = store.put(encryptedItem);
          }
          else {
            putRequest = store.put({ encryptedData });
          }


          putRequest.onsuccess = () => {
            resolve('Record updated successfully');
          };

          putRequest.onerror = () => {
            reject('Failed to update record');
          };
        };

        request.onerror = () => {
          reject('Failed to open database for update');
        };
      });
    });
  }

  openDatabase(): Promise<IDBDatabase> {

    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, currentVersion);

        request.onsuccess = (event) => {
          resolve((event.target as IDBOpenDBRequest).result);
        };

        request.onerror = (event) => {
          reject('Database failed to open: ' + (event.target as IDBOpenDBRequest).error);
        };
      });
    })

  }
  checkStoreExists(storeName: string): Promise<boolean> {
    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, currentVersion);

        request.onsuccess = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          const storeExists = db.objectStoreNames.contains(storeName);
          db.close();
          resolve(storeExists);
        };

        request.onerror = () => {
          reject('Failed to open database');
        };
      });
    })
  }
  

  // getCurrentDatabaseVersion(): Promise<number> {
  //     return new Promise((resolve, reject) => {
  //         console.log('getcurrentdbversion');
  //         const request = indexedDB.open(this.indexDBName);

  //         request.onsuccess = (event) => {
  //           const db = (event.target as IDBOpenDBRequest).result;
  //           console.log('dbversion - ' + db.version);
  //           resolve(db.version);
  //           db.close();
  //         };
    
  //         request.onerror = () => {
  //           reject('Failed to open database');
  //         };      
  //     });
  // }


getCurrentDatabaseVersion(): Promise<number> {
  return new Promise((resolve, reject) => {
    if (this.dbConnection) {
      resolve(this.dbConnection.version);
      return;
    }
    
    const request = indexedDB.open(this.indexDBName);

    request.onsuccess = (event) => {
      this.dbConnection = (event.target as IDBOpenDBRequest).result;
      console.log('dbversion - ' + this.dbConnection.version);
      resolve(this.dbConnection.version);
      this.closeDatabase();
    };

    request.onerror = () => {   
      reject('Failed to open database');
    };
  });
}

closeDatabase() {
  if (this.dbConnection) {
    this.dbConnection.close();
    this.dbConnection = null;
  }
}

  getByKey<T>(storename: string, key: any): Observable<T> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        return from(new Promise<T>((resolve, reject) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event: any) => {
            const db = event.target.result;
            const transaction = db.transaction([storename], 'readonly');
            const store = transaction.objectStore(storename);
            const getRequest = store.get(key);

            getRequest.onsuccess = () => {
              if (getRequest.result) {
                resolve(decodeDataWithToken(getRequest.result.encryptedData, localStorage.getItem(AppConstants.AUTH_TOKEN)));
              }

            };

            getRequest.onerror = (event: any) => {
              reject('Error retrieving data from store');
            };
          };

          request.onerror = (event: any) => {
            reject('Error opening database');
          };
        }));
      })
    );
  }
  getCountOfRecords(storeName: string): Observable<number> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        // Open the database with the current version before updating
        return new Observable<any>((observer) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const countRequest = store.count();

            countRequest.onsuccess = () => {
              observer.next(countRequest.result);
              observer.complete();
            };
            countRequest.onerror = () => {
              observer.error('Failed to count records in the store');
            };
          };
          request.onerror = () => {
            observer.error('Failed to open database');
          };
        });
      })
    );
  }


  add(storeName: string, item: any): Observable<number> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        // Open the database with the current version before updating
        return new Observable<any>((observer) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            let addRequest = null;
            const encryptedData = encodeDataWithToken(item, localStorage.getItem(AppConstants.AUTH_TOKEN))
            if (item.id) {
              const encryptedItem = { id: item.id, encryptedData };
              addRequest = store.add(encryptedItem);
            }
            else {
              addRequest = store.add({ encryptedData });
            }
            //const addRequest = store.add(item);
            addRequest.onsuccess = () => {
              observer.next(addRequest.result);
              observer.complete();
            };
            addRequest.onerror = (event) => {
              observer.error('Failed item add to store');
            };
          };
          request.onerror = (event) => {
            observer.error('Failed to open database for add');
          };
        });
      })
    );
  }

  bulkAdd(storeName: string, items: any[]): Observable<number[]> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        // Open the database with the current version before updating
        return new Observable<any>((observer) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const ids: number[] = [];
            items.forEach(item => {
              let addRequest = null;
              const encryptedData = encodeDataWithToken(item, localStorage.getItem(AppConstants.AUTH_TOKEN))
              if (item.id) {
                const encryptedItem = { id: item.id, encryptedData };
                addRequest = store.add(encryptedItem);
              }
              else {
                addRequest = store.add({ encryptedData });
              }

              // store.add(item); // store.add('item', item.id);
              addRequest.onsuccess = () => {
                ids.push(addRequest.result as number);
                if (ids.length === items.length) {
                  observer.next(ids);
                  observer.complete();
                }
              };
            });
          };
          request.onerror = (event) => {
            observer.error('Failed to open database for bulk add');
          };
        });
      })
    );
  }
  delete(storeName: string, id: number): Observable<any> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        return new Observable<any>((observer) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const deleteRequest = store.delete(id);

            deleteRequest.onsuccess = () => {
              observer.next(`Item with id ${id} deleted successfully`);
              observer.complete();
            };

            deleteRequest.onerror = () => {
              observer.error(`Failed to delete item with id ${id}`);
            };
          };

          request.onerror = () => {
            observer.error('Failed to open database for delete operation');
          };
        });
      })
    );
  }
  clearAllTables(dbName: string): Promise<void> {
    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise((resolve, reject) => {
        const newVersion = currentVersion + 1;
        const request = indexedDB.open(dbName, newVersion);

        request.onupgradeneeded = (event: any) => {
          const db = event.target.result;

          if (db.objectStoreNames.length === 0) {
            console.warn('No object stores found in the database.');
            resolve();
            return;
          }

          for (const storeName of db.objectStoreNames) {
            db.deleteObjectStore(storeName);
          }
        };

        request.onsuccess = (event: any) => {
          const db = event.target.result;
          db.close();
          resolve();
        };

        request.onerror = (event: any) => {
          console.error('Error opening database:', event);
          reject(event.target.error);
        };
      });
    });
  }
  deleteDatabase() {
    var DBDeleteRequest = window.indexedDB.deleteDatabase(this.indexDBName);
    DBDeleteRequest.onblocked = function(event) {
        console.warn  ("Error message: Database in blocked state. ");
    };
    DBDeleteRequest.onerror = function(event) {
      console.warn("Error deleting database.");
    };
    
    DBDeleteRequest.onsuccess = function(event) {
      console.warn("Database deleted successfully");
    
    };
  }
  

  getFilteredDataFromLocalDB(
    storename: string,
    option: any = {},
    filterOffset: number = 0,
    filterLimit: number = 0,
    filterObjectName: string = null,
    localFilter_Option: any = null,
  ): Observable<any[]> {

    if (localFilter_Option) {
      option.filters = localFilter_Option;
    }
    console.log('optioninlocdbfilter', option);

    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        return from(new Promise<any[]>((resolve, reject) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event: any) => {
            const db = event.target.result;
            const transaction = db.transaction([storename], 'readonly');
            const store = transaction.objectStore(storename);
            const getAllRequest = store.getAll();

            getAllRequest.onsuccess = () => {
              let dbData = getAllRequest.result;

              // Decrypt the data before proceeding
              dbData = dbData.map(item => {
                try {
                  // Decrypt the 'encryptedData' field
                  const token = localStorage.getItem(AppConstants.AUTH_TOKEN)
                  const decryptedData = decodeDataWithToken(item.encryptedData, token);
                  return { ...decryptedData, id: item.id }; // Keep the ID and decrypted data
                } catch (error) {
                  console.error('Error decrypting data:', error);
                  return null;
                }
              }).filter(item => item !== null); // Remove null values caused by decryption errors

              // Apply sorting if specified
              if (option.sort && option.sort.length > 0) {
                const sort = option.sort[0];
                if (sort.direction === 'asc') {
                  // dbData.sort((a, b) => a.id - b.id);
                  // dbData.sort((a, b) => a[sort.colname].toLowerCase().localeCompare(b[sort.colname].toLowerCase()));
                  // Sort by `id` first
                  dbData.sort((a, b) => a.id - b.id);

                  // Sort by the column value (considering both strings and numbers)
                  dbData.sort((a, b) => {
                    const valA = a[sort.colname];
                    const valB = b[sort.colname];

                    if (typeof valA === 'string' && typeof valB === 'string') {
                      // Both values are strings, apply localeCompare with toLowerCase for ascending
                      return valA.toLowerCase().localeCompare(valB.toLowerCase());
                    } else if (typeof valA === 'number' && typeof valB === 'number') {
                      // Both values are numbers, do a numeric comparison for ascending
                      return valA - valB;
                    } else {
                      // Handle mixed types, convert to string for comparison if needed
                      return String(valA).localeCompare(String(valB));
                    }
                  });
                } else if (sort.direction === 'desc') {
                  // dbData.sort((a, b) => b.id - a.id);
                  // dbData.sort((a, b) => b[sort.colname].toLowerCase().localeCompare(a[sort.colname].toLowerCase()));

                  // Sort by `id` first
                  dbData.sort((a, b) => b.id - a.id);

                  // Sort by the column value (considering both strings and numbers)
                  dbData.sort((a, b) => {
                    const valA = a[sort.colname];
                    const valB = b[sort.colname];

                    if (typeof valA === 'string' && typeof valB === 'string') {
                      // Both values are strings, apply localeCompare with toLowerCase
                      return valB.toLowerCase().localeCompare(valA.toLowerCase());
                    } else if (typeof valA === 'number' && typeof valB === 'number') {
                      // Both values are numbers, do a numeric comparison
                      return valB - valA;
                    } else {
                      // Handle mixed types, convert to string for comparison if needed
                      return String(valB).localeCompare(String(valA));
                    }
                  });
                }
              }

              // Apply filters if specified
              if (option.filters && option.filters.length > 0) {
                dbData = dbData.filter(item => {
                  return option.filters.every(filter => {
                    if (filter.conditions) {
                      // Apply OR/AND logic within conditions
                      const orConditions = filter.conditions.filter(cond => cond.operator === 'OR');
                      const andConditions = filter.conditions.filter(cond => cond.operator !== 'OR');

                      const andResult = andConditions.every(cond => this.applyFilterCondition(item, cond));
                      const orResult = orConditions.some(cond => this.applyFilterCondition(item, cond));

                      return (andConditions.length === 0 || andResult) && (orConditions.length === 0 || orResult);
                    } else {
                      // Apply single condition if no nested conditions
                      if (filterObjectName) {
                        return this.applyFilterCondition(item[filterObjectName], filter);
                      } else {
                        return this.applyFilterCondition(item, filter);
                      }
                    }
                  });
                });
              } else if (!option.filters) {
                dbData = dbData.filter(item => {
                  return Object.keys(option).every(key => {
                    if (item.hasOwnProperty(key)) {
                      if (typeof option[key] === 'string') {
                        // For string-based filters, check if the item contains the value
                        return item[key]?.toLowerCase().includes(option[key]?.toLowerCase());
                      } else {
                        // For non-string filters, check equality
                        return item[key] === option[key];
                      }
                    } else {
                      return true;
                    }
                  });
                });
              }

              // Apply pagination (offset and limit)
              resolve(dbData.slice(filterOffset, filterOffset + filterLimit));
            };

            getAllRequest.onerror = (event: any) => {
              reject('Error retrieving data from store');
            };
          };

          request.onerror = (event: any) => {
            reject('Error opening database');
          };
        }));
      })
    );
  }


  private  applyFilterCondition(item: any, conddet: any): boolean {
    if (item != null) {
      const value = item[conddet.colname]?.toString().toLowerCase();
      const filterValue = conddet.value?.toString().toLowerCase();

      console.log('value', value);
      console.log('filterValue', filterValue);
      if(value && value.length > 0 && filterValue && filterValue.length > 0)
      {
        switch (conddet.condition) {
          case 'equalto':
            return value === filterValue;
          case 'contains':
            return value?.includes(filterValue);
          case 'startswith':
            return value?.startsWith(filterValue);
          default:
            return true;
        }
      }else
      {
        return false
      }
      
    }
    else {
      return false
    }

  }


}

// interface HasId {
//   id: number; // You can adjust the type of 'id' as necessary, e.g., string or number
// }

