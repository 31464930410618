export enum CreatedSource {
    User = "user",
    Import = "import"
}

export enum ModuleConstants {
    ITEM = 1,
    KIT = 2,
    KIT_ASSEMBLE = 3,
    SUPPLIER = 4,
    MANUFACTURER = 5,
    CUSTOMERS = 6,
    SERVICE_TICKETS = 7,
    DISTRIBUTORS = 8,
    WARRANTY = 9,
    TERMS = 10,
    SERVICE_CONTRACTS = 11,
    ITEM_CATEGORIES = 12,
    SALES_ORDERS = 13,
    INVOICE = 14,
    CATALOG=15,
    CATALOG_VIEW=16,
    DISPATCH=17,

    RING2VOICE_DIDS = 20,
    RING2VOICE_FORWARD_BINS = 21,
    RING2VOICE_CALL_REPORTS = 22,
    RING2VOICE_DID_REPORTS = 23,
    RING2VOICE_SIP_ENDPOINTS = 24,
    RING2VOICE_BLOCK_LIST = 25,
    SERVICE_TICKET_SUBTASK = 26,
    RING2VOICE_TIME_FRAME = 27,
}

export enum TwoFactorTypes {
  EMAIL = 1,
  GOOGLE_AUTHENTICATOR = 2
}

export enum AppEventType {
  SuborgSwitch = 'SUBORG_SWITCH',
  UserUpdate = 'USER_UPDATE',
  CommonPanelEvent = 'COMMON_PANEL_EVENT'

}

export enum CommonPanelEvents {

  ClosePanel = 'CLOSE_PANEL',
  OpenPanel = 'OPEN_PANEL',
  
  OpenContact = 'OPEN_CONTACT',
  OpenContactView = 'OPEN_CONTACT_VIEW',
  DeleteContact = 'DELETE_CONTACT',
  SaveContact = 'SAVE_CONTACT'
}

export enum StatusValueEnum {
  ONLINE =  'Online',//'Ready',
  BUSY = 'Busy',
  OFFLINE = 'Offline',
  ON_CALL = 'On Call'
}

export enum StatusInt {
  ONLINE = 1,
  BUSY = 2,
  OFFLINE = 3,
  ON_CALL = 4
}

export enum NumberBlockTypeEnum {
  USER_ADDED = 'User_added',
  AUTO_ADDED = 'Auto_added',
  BOTH = 'Both',
  NONE = 'None'
}

export enum CallRingTypeEnum {
  NUMBER = "Number",
  SIP = "Sip",
  SMART_SIP_GROUP = "Smart_Sip_Group"
}

export enum CallRoutingOptionEnum {
  RING = 'Ring',
  INTELLIGENT_MENU = 'Intelligent Menu',
  ANNOUNCEMENT = 'Announcement',
  TIME_BASED_ROUTING = 'Time Based Routing'
}

export enum AfterTimeoutOption {
  VOICEMAIL = 'Voicemail',
  RING = 'Ring',
  QUEUE = 'Queue'
}

export enum NavigateToModuleEnum {
  CCA = 'caller-connection-avenue',
  SMS_ROUTE = 'sms-route',
  DIALER = 'dialer',
}