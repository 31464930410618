import { Company, CompanyUser } from '../models/companies.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerContact } from '../models/companies.models';
import { catchError } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { BaseService } from 'app/core/services/base.service';
import { DataFilterResponse } from 'app/core/models/grid-filter.models';
import { SfHttpService } from 'app/shared/services/sf-http.service';
import { AnyPtrRecord } from 'dns';
@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {

  public externalFilter: any[] = [];

  private storeName: string = 'customer_contact'

  constructor(private http: HttpClient,private sfHttpService: SfHttpService) { super() }

  getAllTenantCustomers(suborg_id = this.suborgId) {
    return this.http.get<Company[]>(`${this.baseURL}/tenant_customer/?suborg=${suborg_id}`);
  }

  getAll(option: any, offset: number = 0, pageSize: number = 50, suborg_id: number = this.suborgId) {
    let url = `${this.baseURL}/company/filter/?limit=${pageSize}&offset=${offset}&suborg=${suborg_id}`;
    return this.http.post<DataFilterResponse<Company[]>>(url, option).pipe(
      catchError(() => of())
    );
  }

  fetchCustomeContactsFiltered_LocalDB(subjectName$:Subject<any>, option: any = {}, limit: number = 0, offset: number = 0, 
    suborg_id: number = this.suborgId):any {

      const apiCallurl = `${this.baseURL}/filter_company_users/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
      return this.sfHttpService.post<CustomerContact>(apiCallurl,option,this.storeName,limit,offset,subjectName$,null,500).subscribe(res=>{});
  }

  getCompanyUserById_LocalDB(subjectName$:Subject<any>,option: any = {}, limit: number = 0, offset: number = 0, 
    suborg_id: number = this.suborgId):any
  {
    console.log('--apicalled for contactinfobyid')
    const apiCallurl = `${this.baseURL}/filter_company_users/?limit=${limit}&offset=${offset}&suborg=${suborg_id}`;
    return this.sfHttpService.post<CustomerContact>(apiCallurl,option,this.storeName,limit,offset,subjectName$,null,500).subscribe(res=>{});
  }

  createCompany(body:any){
    let url = `${this.baseURL}/company/`;
    return  this.http.post<DataFilterResponse<any>>(url,body);
  }

  updateCompanyById(id:number, body: any){
    let url = `${this.baseURL}/company/${id}/`;
    return  this.http.put<DataFilterResponse<any>>(url,body);
  }

  getCompanyById(id:number){
    let url = `${this.baseURL}/company/${id}/`;
    return this.http.get<DataFilterResponse<any>>(url);
  }

  deleteCompany(id:number){
    let url = `${this.baseURL}/company/${id}/`;
    return this.http.delete<DataFilterResponse<any>>(url);
  }

  getCustomerContactById(id: any) {
    return this.http.get<DataFilterResponse<CustomerContact>>(`${this.baseURL}/tenant_customer/contacts/${id}`);
  }

  createCustomerContact(model: CustomerContact) {
    return this.http.post<DataFilterResponse<CustomerContact>>(`${this.baseURL}/company/users/`, model);
  }

  updateCustomerContact(id: string, model: CustomerContact) {
    return this.http.put<DataFilterResponse<CustomerContact>>(`${this.baseURL}/company/users/${id}/`, model);
  }

  deleteCompanyUser(id:number){
    let url = `${this.baseURL}/company/users/${id}/`;
    return this.http.delete<DataFilterResponse<any>>(url);
  }
}





