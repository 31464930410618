import { environment } from "environments/environment";
import { UserTypes } from "../enums/user-types";
import { AppConstants } from "../constants/app.constants";

export abstract class BaseService{
    public baseURL: string = environment.apiURL;

    public webhookURL : string = environment.webhookURL;

    public dataLimit: number = 50;

    public get userType(): UserTypes {
        return localStorage.getItem(AppConstants.USER_TYPE) as UserTypes;
    }

    public get tenantId(): number {
        return parseInt(localStorage.getItem(AppConstants.TENANT_ID));
    };

    public get suborgId(): number {
        return parseInt(localStorage.getItem(AppConstants.CURRENT_SUBORG));
    }

    public get isSuperUser(): boolean {
        return this.userType === UserTypes.SuperUser;
    }

    public get isAdmin(): boolean {
        return this.userType === UserTypes.Admin;
    }

    public get isCustomer(): boolean {
        return this.userType === UserTypes.Customer;
    }

    public get isTenantUser(): boolean {
        return this.userType === UserTypes.TenantUser;
    }

    public get userId(): number {
        return parseInt(localStorage.getItem(AppConstants.USER_ID));
    }

    public get isAllSuborg(): boolean {
        return !this.isSuperUser && this.suborgId === -1;
    }

    public get userEmail(): string {
        return JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))["email"];
    }
    public get userTimeZone(): string {
        let tz=JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))["time_zone"];
         if(!tz){
            tz="UTC";
         }
        return tz;
    }

    public get userSesVerified(): boolean {
        return JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))["ses_verified"];
    }

    public get userFirstName(): string {
        return JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))["firstname"];
    }

    constructor() { }

    public getSuborgs() {
        const suborgs: any[] = JSON.parse(localStorage.getItem(AppConstants.USER_SUBORGS)) || [];
        return suborgs.filter(item => item.id !== -1);
    }

    public get getCurrentSuborgName() {

        return this.getSuborgs().filter(item => item.id === this.suborgId)[0].name;
    }

    // public createModel<T>(C: { new(): T }): T {
    //     const instance = new C();
    //     if (instance instanceof WHBaseModel) {
    //         instance.customer = this.customerId;
    //         instance.suborg = this.suborgId;
    //     }
    //     return instance;
    // }

    public get userCallcenterSubscriptionStatus(): boolean {
        return JSON.parse(localStorage.getItem(AppConstants.USER_AUTH))["is_call_center"] || false;
    }

    public get phoneNumberCount(): number {
        return parseInt(localStorage.getItem(AppConstants.PHONE_NUMBER_COUNT));
    }
}