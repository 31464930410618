import { id } from "../utils/global.functions";
import { WHBaseModel } from "./common.models";

export class StaticFile extends WHBaseModel {

    public id: string;

    public module: string;

    public tags:string;

    public url:string;

    public type:string;

    public name:string;
}

export class ThumbNail {

    public id: string = id();

    public url: string;

    public file: any;

    public name:string;

    public isPrimary:boolean;

    public uploaded:boolean = false;

    public data_id:string;

    public type:string
}
