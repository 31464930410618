import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppEventType, CommonPanelEvents } from 'app/core/enums/common.enum';
import { AppEvent } from 'app/core/models/common.models';
import { CommonService } from 'app/core/services/common.service';
import { sidePanelConstants } from 'app/core/constants/app-shared.constants';
import { DataFilterRequest } from 'app/core/models/grid-filter.models';
import { SubSink } from 'subsink';
import { CompanyService } from 'app/modules/tenant-customers/services/company.service';
import { Company, CompanyUser } from 'app/modules/tenant-customers/models/companies.models';
import { ToastrService } from 'ngx-toastr';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { CallCenterSharedService } from 'app/modules/ring2voice/services/call-center-shared.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'company-user-view',
  standalone: true,
  imports: [CommonModule,PhoneNumberPipe,NgxSkeletonLoaderModule],
  templateUrl: './company-user-view.component.html',
  styleUrl: './company-user-view.component.scss'
})
export class CompanyUserViewComponent {

  @Input() set _companyUserDet(val: CompanyUser)
   { 
    this.companyUserDet = val; 
    if(this.companyUserDet.company != null)
    {
      this.getCompanyDetails(this.companyUserDet.company)
    }

  }
   
  public companyUserDet: CompanyUser;

  public userCompanyDetails: Company

  private subs: SubSink = new SubSink();

  fileUrl: string = environment.imgURL;

  @Output() onDelete: EventEmitter<CompanyUser> = new EventEmitter();

  private viewContactstoreData$ = new Subject<any>();

  isLoading: boolean = false;

  @Input() set id(val: number) {
    this._id = val;
    if(this._id)
    {
      this.getContactInfoById(this._id);
    }
    } 

 private _id: number
 
  constructor(
    private commonservice: CommonService,
    private _tenantCustomerService: CompanyService,
    private _toaster: ToastrService,
    private _callcenterShared : CallCenterSharedService,
    private _commonService: CommonService,
    private _cdr: ChangeDetectorRef

  ){}

  ngOnInit(): void {
    
  }
  ngAfterViewInit() {
    this.getData()
    this.suscribeSidepanelEvents()
  }

  suscribeSidepanelEvents(){

    this.subs.sink = this._commonService.on(AppEventType.CommonPanelEvent).subscribe(event => {
      if (event.payload) {
        if (event.payload.EventName == CommonPanelEvents.SaveContact && this._callcenterShared.showContactAfterSaved) {

          setTimeout(() => {
            this._commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:{EventName:CommonPanelEvents.OpenContactView,
              AdditionalInfo:event.payload.AdditionalInfo.saveInfo}})
            this._callcenterShared.showContactAfterSaved = false
          }, 100);

        }
      }
    })
  }

  getData()
  {
    this.subs.sink = this.viewContactstoreData$.subscribe(res => {
      if(res.data?.result){
        console.log('--res_contact',res.data.result[0])
        this.companyUserDet = res.data.result[0]
        if(res.data.result[0].company > 0)
        {
             this.getCompanyDetails(res.data.result[0].company)
        }
        this.isLoading = false;
        this._cdr.markForCheck();
      }
    })

  }

  getCompanyDetails(id:number){

    this._tenantCustomerService.getCompanyById(id).subscribe({
      next:response=>{
        if(response.http_status==200){
          this.userCompanyDetails = response.data?.result;
        }else{
          this._toaster.error("Failed to fetch company details");
        }
      },
      error: (error)=>{
        console.error(error.error);
        this._toaster.error("Failed to fetch company details");
      }
    })
  }
  getContactInfoById(id:number){
    let request: DataFilterRequest = {

      filters: [{conditions:[
        {
          "colname": "id",
          "condition": "equalto",
          "value": id.toString(),
          "operator": "AND"

          }],"operator": "AND"}],
      sort: [],
      base_filters: []
    };
    this.isLoading = true;
    this._cdr.markForCheck
    this.subs.sink = this._tenantCustomerService.getCompanyUserById_LocalDB(this.viewContactstoreData$,request,1,0)
  }

  onCloseContact()
  {
     const sidePanelConstants:sidePanelConstants ={EventName:CommonPanelEvents.ClosePanel}
     this.commonservice.dispatch(new AppEvent(AppEventType.CommonPanelEvent, sidePanelConstants));

  }

  delete()
  {
    this.onDelete.emit(this.companyUserDet)
  } 
  edit()
  {
    this._callcenterShared.showContactAfterSaved = true
    this._callcenterShared.contactSaveTempData = this.companyUserDet
    this._callcenterShared.contactSaveTempData.company_details = this.userCompanyDetails
    const sidePanelConstants:sidePanelConstants ={EventName:CommonPanelEvents.OpenContact,AdditionalInfo:{AfterSaveEvents:'openAfterSave'}}
    this._commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:sidePanelConstants});
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }



}
