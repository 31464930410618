import { Directive, HostListener, Input } from '@angular/core';
import { CommonService } from 'app/core/services/common.service';
import { sidePanelConstants } from 'app/core/constants/app-shared.constants';
import { AppEventType, CommonPanelEvents } from 'app/core/enums/common.enum';

@Directive({
  selector: '[ShowContactAdd]',
  standalone: true
})
export class ShowContactAddDirective {

  @Input() contactInfo: any

  constructor(private commonService:CommonService) { }

  @HostListener('click')

  onClick() {
    this.openContactView()
  }

  openContactView(){
    const sidePanelConstants:sidePanelConstants ={EventName:CommonPanelEvents.OpenContact}
      if(this.contactInfo)
      {
        sidePanelConstants.AdditionalInfo = this.contactInfo
      }
      this.commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:sidePanelConstants});
  }

}
