import { CommonPanelEvents } from "../enums/common.enum"

export class AppSharedConstants {

    static INDEXDB_NAME = "sf-cachedata"
}


export class sidePanelConstants {
    
    public EventName : CommonPanelEvents

    public AdditionalInfo? : any = {}

}