import { Component, HostListener, OnInit, inject } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { environment } from 'environments/environment';
import { initializeApp } from "firebase/app";
import { CallCenterSharedService } from './modules/ring2voice/services/call-center-shared.service';
// import { CallNotificationService } from './modules/ring2voice/services/call-notification.service';
import { FireMessagingService } from './core/services/fire-messaging.service';
import { AppConstants } from './core/constants/app.constants';
import { ChatService } from './modules/omni-chat/services/chat.service';
import { VersionCheckService } from './core/services/version-check.service';
import { forEach } from 'lodash';
import { SipConnectionService } from './modules/ring2voice/services/sip-connection.service';
import { RegistererState } from 'sip.js';
import { ToastrService } from 'ngx-toastr';
initializeApp(environment.fireBase);

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'], 
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent implements OnInit
{

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any){
        if(this.callcenterSharedService.activeCallerSession || this.callcenterSharedService.holdCallQ.length >0){
            $event.preventDefault();
            return $event.returnValue = "";
        }
        else
            return;     
    }

    @HostListener('window:unload', ['$event'])
    unload($event: any): void {
        this.callcenterSharedService.connection.stop();
    }




    /**
     * Constructor
     */
    constructor(
        private callcenterSharedService : CallCenterSharedService,
        private fireMessagingService: FireMessagingService,
        private router: Router,
        private chatService: ChatService,
        private sipConnectionService: SipConnectionService,
        private toastr: ToastrService,
        private versionCheckService: VersionCheckService)
    {}

    ngOnInit(): void {

        this.listenForServiceWorkerEvents();
        this.versionCheckService.setupVersionCheck();
        console.log('sip connection status', this.callcenterSharedService.isSIPRegistered$.getValue())
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
              this.closeNotifications();
            }
            else if (document.visibilityState === 'hidden') {
                let calls = this.callcenterSharedService._callQ.getValue().filter(x => x.customValues?.notification_established == true)
                if(calls.length > 0){
                    calls.forEach(element => {
                        if(element.customValues?.isCallOnHold)
                        {
                            this.fireMessagingService.showUnholdCallNotification(element.callDetails);
                        }
                       
                        else
                            this.fireMessagingService.showHoldCallNotification(element.callDetails);
                    });
                }
            }
        });

        //this.sipConnectionService.setConnectionTimeout();

    }

    private listenForServiceWorkerEvents() {

        navigator.serviceWorker.addEventListener('message', event => {
            console.warn('eventdata',event)
            console.log('eventdata',event.data.value)
            if(event.data.value)
            {
                console.warn('isUserLogged:',localStorage.getItem(AppConstants.AUTH_TOKEN) !== null ? 'true':'false')
                if(localStorage.getItem(AppConstants.AUTH_TOKEN) !== null)
                    {
                        this.fireMessagingService.pushNotification.next(event.data.value)
                        // redirection
                        let action = event.data.value?.data?.notificationAction || null;
                        if(action == 'open'&&event.data.value.data.type == 'CHAT'&&!this.isDesiredPage('omni-chat')){
                            this.redirectToDesiredPage('/omni-chat',event.data.value);
                        }

                    }
            }
            else if(event.data.type = "ACTIVE_CALL_ACTION"){
                this.fireMessagingService.pushNotification.next(event)
            }  
        });
    }

    isDesiredPage(endPoint): boolean {
        const currentUrl = this.router.url;
        return currentUrl.includes(endPoint);
    }
    
    redirectToDesiredPage(url, data): void {
        // Navigate to the chat page
        this.router.navigateByUrl(url);
        this.chatService.setNotificationData(data);
    }

    closeNotifications() {
        setTimeout(function(){
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                registrations.forEach((registration) => {
                  registration.getNotifications().then((notifications) => {
                    notifications.forEach(notification => {
                        if (notification.tag.includes('Call-Notification')) { // Check if tag contains the substring
                          notification.close();
                        }
                      });
                  });
                });
              });
        }, 500)
    }

    ngOnDestroy() {
        this.callcenterSharedService.disconnect();

        console.log('You are on AppComponent ngOnDestroy');

    }


}
