import { Directive, ElementRef, HostListener, Input, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { ChatService } from "app/modules/omni-chat/services/chat.service";
import { CallCenterSharedService } from "app/modules/ring2voice/services/call-center-shared.service";

@Directive({
    selector: '[InitiateChat]',
    standalone: true
  })
  export class InitiateChatDirective {
  
    @Input({required:true}) toNumber: any;

    @Input() row: any;

    private tooltip: HTMLElement;
  
    constructor(private _chatSertvice: ChatService,
        private router: Router,
        private callcenterShared : CallCenterSharedService,
        private el: ElementRef, private renderer: Renderer2
    ) { }
  
    /**
     * @Input callType: The type of call to initiate (e.g., 'contact' or 'sip').
     * @Input fromNumber: The source of the call.
     * @Input toNumber: The destination of the call.
     * @Input agentsipuri: The SIP URI of the agent initiating the call.
     * @Input callto_agent_id (optional): The ID of the agent to call (if callType=='sip).
     */
  
    @HostListener('click')
    onClick() {
      this.callcenterShared.popupOpenStatus.next(false);
      this._chatSertvice.toNumber = this.toNumber;
      this.router.navigate(['omni-chat']);
      if (this.tooltip) {
        this.renderer.removeChild(document.body, this.tooltip);
        this.tooltip = null;
      }
    }

    @HostListener('mouseenter') onMouseEnter() {
      this.showTooltip();
    }
  
    @HostListener('mouseleave') onMouseLeave() {
      this.hideTooltip();
    }
  
    private showTooltip() {
      this.tooltip = this.renderer.createElement('span');
      const text = this.renderer.createText(this.getTooltipText());
      this.renderer.appendChild(this.tooltip, text);
      this.renderer.appendChild(document.body, this.tooltip);
      this.renderer.setStyle(this.tooltip, 'position', 'fixed');
      this.renderer.setStyle(this.tooltip, 'background', '#333');
      this.renderer.setStyle(this.tooltip, 'color', '#fff');
      this.renderer.setStyle(this.tooltip, 'padding', '5px');
      this.renderer.setStyle(this.tooltip, 'borderRadius', '5px');
      this.renderer.setStyle(this.tooltip, 'z-index', '1000');
      this.renderer.setStyle(this.tooltip, 'white-space', 'nowrap');
  
      const hostPos = this.el.nativeElement.getBoundingClientRect();
      const tooltipPos = this.tooltip.getBoundingClientRect();
  
      let top = hostPos.top - tooltipPos.height - 5;
      let left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;

       // Ensure tooltip is within the viewport
      top = Math.max(top, 0); // Prevent moving above the viewport
      // Ensure tooltip does not overflow the right edge of the viewport
      if (left + tooltipPos.width > window.innerWidth-20) {
        left = window.innerWidth - tooltipPos.width - 20; // Adjust to be within the viewport
      }

      // Ensure tooltip does not overflow the left edge of the viewport
      if (left < 0) {
        left = 10; // Adjust to be within the viewport
      }

      this.renderer.setStyle(this.tooltip, 'top', `${top}px`);
      this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
    }
  
    private hideTooltip() {
      if (this.tooltip) {
        this.renderer.removeChild(document.body, this.tooltip);
        this.tooltip = null;
      }
    }
  
    getTooltipText(){
        let tooltip = ["Chat"]
        if(this.row?.direction == 'inbound'){
          if(this.row?.customer_contact_name?.trim())
            tooltip.push(this.row?.customer_contact_name.trim())
          else if(this.row?.created_by_name?.trim())
            tooltip.push(this.row?.created_by_name.trim())
          else
            tooltip.push(this.toNumber)
        }
        else if(this.row?.direction == 'outbound'){
          if(this.row?.accepted_by_name?.trim())
            tooltip.push(this.row?.accepted_by_name.trim())
          else if(this.row?.call_to_name?.trim())
            tooltip.push(this.row?.call_to_name.trim())
          else
            tooltip.push(this.toNumber)
        }
        else
          tooltip.push(this.toNumber)
        return tooltip.join(" ")
    }
  
  }