<div class="p-1">
    <div class="flex justify-between items-center mb-2 mt-2">
        <button class="ml-auto text-gray-500 hover:text-gray-700" (click)="onCloseContact()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
    </div>
    <div class="flex justify-between items-center p-1 tracking-[-0.88px]">
        <div class="font-semibold text-[22px]">
            Contact Details
        </div>
        <div class="flex items-center space-x-4">
            <button matTooltip="Edit" class="w-5 h-5 flex items-center justify-center ml-2 gray-outline" (click)="edit()" [disabled]="isLoading == true">
                <img *ngIf="isLoading == false" alt="edit" src="../../../../../assets/icons/common-icons/edit-2.svg">
                <img *ngIf="isLoading == true" alt="edit" src="../../../../../assets/icons/common-icons/edit-gray.svg">
            </button>
            <div class="h-[16px] border-l-[1px] bg-[#292d32] ml-2"></div>
            <button matTooltip="Delete" class="w-5 h-5 flex items-center justify-center ml-2 group" (click)="delete()" [disabled]="isLoading == true">
                <img *ngIf="isLoading == false" alt="trash" src="../../../../../assets/icons/common-icons/trash-red-icon.svg">
                <img *ngIf="isLoading == true" alt="trash" src="../../../../../assets/icons/common-icons/trash-gray.svg">
            </button>
        </div>
    </div>
    <div  *ngIf="isLoading">
        <div class="flex justify-center mt-3">
            <ngx-skeleton-loader  [theme]="{'border-radius': '50%', 'width': '130.5px', 'height': '130.5px',}"
            ></ngx-skeleton-loader>
           
        </div>
        <div class="flex flex-col items-center">
            <ngx-skeleton-loader class="w-2/5  opacity-50" count="1" appearance="line" [theme]="{'margin-bottom':'0px',background:'#e9e9e9',height:'23px',width:'222px' }"/>
            <!-- <ngx-skeleton-loader class="w-[15%]" count="1" appearance="line" [theme]="{'margin-bottom':'0px',background:'#e9e9e9',height:'18px'}"/> -->
        </div>

        <div class="flex flex-col items-center mt-5">
            <ngx-skeleton-loader class="w-[100%] opacity-50" count="1" appearance="line" [theme]="{'margin-bottom':'0px',background:'#e9e9e9',height:'20px'}"/>
            <!-- <ngx-skeleton-loader class="w-[100%]" count="1" appearance="line" [theme]="{'margin-bottom':'0px',background:'#e9e9e9',height:'37px'}"/> -->
            <ngx-skeleton-loader class="w-[100%] opacity-50 mt-2" count="1" appearance="line" [theme]="{'margin-bottom':'0px',background:'#e9e9e9',height:'37px'}"/>
        </div>
       
       
        
        <!-- <ngx-skeleton-loader class="w-3/12 mr-5" count="1" appearance="line" [theme]="{'margin-bottom':'0px',background:'#e9e9e9',height:'18px'}"/> -->

    </div>
    <div class="div" *ngIf="!isLoading">

        <div class="flex justify-center mt-3">
            <div class="rounded-full p-1" [ngClass]="companyUserDet?.image ? 'border-2 border-gray-300' : ''">
                <div class="rounded-full w-[141.5px] h-[141.5px] overflow-hidden">
                    @if(companyUserDet?.image){
                    <img [src]="fileUrl + companyUserDet.image + '.jpg'" class="w-full h-full object-cover">
                    }
                    @else{
                    <img src="../../../../../assets/icons/avatar-roundedgray.svg">
                    }
                </div>
            </div>
        </div>
        <div class="text-center mt-2">
            <div class="font-semibold text-[18px]">
                {{ companyUserDet?.first_name || 'Name not available' }}
            </div>
            <!-- <div class="text-[14px] text-gray-500">
                {{ companyUserDet?.designation || 'Designation not available' }}
            </div> -->
        </div>
    
        <!-- Personal Information Section -->
        <div class="mt-3">
            <div class="text-[18px] font-semibold tracking-[-0.36px]">
                Personal Information
            </div>
            <div class="space-y-0">
                <!-- Full Name -->
        <div class="flex items-center p-3 bg-white rounded-lg">
            <img src="../../../../../assets/icons/user.svg" alt="User Icon" class="w-5 h-5 mr-1">
            <span class="text-base font-medium w-1/4">Full Name</span>
            <span class="text-base font-normal w-3/4">{{ companyUserDet?.first_name || 'Not available' }}{{ companyUserDet?.last_name ? ' ' + companyUserDet.last_name : '' }}</span>
        </div>
    
        <!-- Phone -->
        <div class="flex items-center p-3 gray-bg-container">
            <img src="../../../../../assets/icons/call-center/call-outgoing black.svg" alt="Phone Icon" class="w-5 h-5 mr-1">
            <span class="text-base font-medium w-1/4">Phone</span>
            <div class="text-base font-normal w-3/4">
                    @if(companyUserDet?.phone)
                    {
                        <div>{{companyUserDet?.phone | phoneNumber: false}}</div>
                    }
                    @if(companyUserDet?.mobile)
                    {
                        <div>{{companyUserDet?.mobile | phoneNumber: false}}</div>
                    }
                    @if (!companyUserDet?.phone && !companyUserDet?.mobile) {
                        <div>Not available</div>
                    }
            </div>
           
        </div> 
    
                <!-- Contact Email -->
                <div class="flex items-center p-3 bg-white rounded-lg">
                    <i  matprefix="" class="icon-sms flex items-center text-[20px] mr-1"></i>
                    <span class="text-base font-medium w-1/4">Contact Email</span>
                    <div class="text-base font-normal w-3/4">
                        @if(companyUserDet?.email)
                        {
                            <a  [href]="'mailto:' + companyUserDet.email" class="underline text-blue-600">{{ companyUserDet.email }}
                            </a>
                        }
                        @if(companyUserDet?.secondary_email)
                        {
                            <a  [href]="'mailto:' + companyUserDet.secondary_email" class="underline text-blue-600">{{ companyUserDet.secondary_email }}
                            </a>
                        }
                        @if (!companyUserDet?.email && !companyUserDet?.secondary_email) {
                            <div>Not available</div>
                        }
                    </div>     
                </div>
    
                <!-- Address -->
                <div class="flex items-center p-3 gray-bg-container">
                    <i class="icon-location flex items-center text-[20px] mr-1"></i>
                    <span class="text-base font-medium w-1/4">Address</span>
                    <span class="text-base font-normal w-3/4">{{ companyUserDet?.address || 'Not available' }}</span>
                </div>
            </div>
        </div>
    
        <div class="mt-4">
            <div class= "text-[18px] font-semibold tracking-[-0.36px] mb-4">
                Work Information
            </div>
            <div class="space-y-0"> 
                <div class="flex items-center p-3 gray-bg-container">
                    <i class="icon-building-4 text-2xl mr-1"></i>
                    <span class="text-base font-medium w-1/4">Company Name</span>
                    <span class="text-base font-normal w-3/4">{{ userCompanyDetails?.name || 'Not available' }}</span>
                </div>
    
                <div class="flex items-center p-3 bg-white rounded-lg">
                    <i class="icon-briefcase flex items-center text-[20px] mr-1"></i>
                    <span class="text-base font-medium w-1/4">Designation</span>
                    <span class="text-base font-normal w-3/4">{{ companyUserDet?.designation || 'Not available' }}</span>
                </div>
                <div class="flex items-center p-3 gray-bg-container">
                    <i class="icon-location flex items-center text-[20px] mr-1"></i>
                    <span class="text-base font-medium w-1/4">Address</span>
                    <span class="text-base font-normal w-3/4">{{ userCompanyDetails?.address || 'Not available' }}</span>
                </div>
                <div class="flex items-center p-3 bg-white rounded-lg">
                    <img src="../../../../../assets/icons/call-center/call-outgoing black.svg" alt="Phone Icon" class="w-5 h-5 mr-1">
                    <span class="text-base font-medium w-1/4">Phone</span>  
                    <div class="text-base font-normal w-3/4">
                        @if(userCompanyDetails?.phone_1)
                        {
                            <div>{{userCompanyDetails?.phone_1 | phoneNumber: false}}</div>
                        }
                        @if(userCompanyDetails?.phone_2)
                        {
                            <div>{{userCompanyDetails?.phone_2 | phoneNumber: false}}</div>
                        }
                        @if (!userCompanyDetails?.phone_1 && !userCompanyDetails?.phone_2) {
                            <div>Not available</div>
                        }
                    </div>
    
                </div>
                <div class="flex items-center p-3 gray-bg-container">
                    <i class="icon-global flex items-center text-[20px] mr-1"></i>
                    <span class="text-base font-medium w-1/4">Website</span>
                    <div class="text-base font-normal w-3/4">
                        <a *ngIf="companyUserDet?.website"  [href]="companyUserDet.website" target="_blank" rel="noopener noreferrer"
                        class="underline text-blue-600">
                           {{ companyUserDet.website }}
                       </a>
                       <span *ngIf="!companyUserDet?.website">Not available</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <div class="text-[18px] font-semibold tracking-[-0.36px] mb-2">
                Additional Information
            </div>
            <p class="text-base font-normal">
                {{ companyUserDet?.additional_information || 'No additional information available' }}
            </p>
        </div>
    </div>
</div>