export const environment = {
    production: false,
    apiURL:'https://qa.simplyfuse.com/api/v1',
    fileURL:'https://qa.simplyfuse.com/media',
    imgURL:'https://qa.simplyfuse.com/api/v1/static/files/',
    webhookURL: 'https://qa.simplyfuse.com/api/pbx',
    appVersion: "1.4.1",

    fireBase: {

        apiKey: "AIzaSyDbRAZrpjnvxgQzr29iAEKtOawZbtpKoYE",
        authDomain: "simplyfuse-e9e5a.firebaseapp.com",
        projectId: "simplyfuse-e9e5a",
        storageBucket: "simplyfuse-e9e5a.appspot.com",
        messagingSenderId: "975407519921",
        appId: "1:975407519921:web:758cfba6b731391b75ea90",
        measurementId: "G-74CST0EZGB",
        vapidKey:"BCTtXMDI3Fr_V8_tTDYwgO6pbAh0CeKN1l4tdH91ArxJfLtNK6wQzQYlU7sHt7IZVAHlRs9WCu1vxyY94SapbBg"
    },

    cardKnox: {
        xKey: 'ifields_sathwicdev1699850c95bd4759b4b4ab9c856',
        xName: 'SimplyFuse DEV',
        xVersion: '1.0'
    }
};